import { Project } from '../project/project.model';
import { LineItem } from '@app/data/models/contract/line-item.model';
import { PaymentSchedule } from '@app/data/models/contract/payment-schedule.model';
import { BaseContract } from '@app/data/models/contract/base-contract.model';
import { BaseModel } from '../base.model';
import { Contractor } from '../contractor.model';

export class ContractorContract extends BaseContract {
    project: Project;
    contractor: Contractor;
    itemBased: boolean;
    suretyBond: number;
    totalContractPrice: number;
    lineItems: LineItem[];

    getLineItems() {
        return this.getRelationArray(LineItem, 'line-items');
    }
}
