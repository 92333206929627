import { Injectable, Injector } from '@angular/core';
import { RestService } from 'angular4-hal';
import { ClientContract } from '@app/data/models/contract/client-contract.model';
import { BaseService } from '../base-service';

@Injectable({
  providedIn: 'root'
})
export class ClientContractService extends BaseService<ClientContract> {
  constructor(injector: Injector) {
    super(ClientContract, 'client-contracts', injector);
  }
}
