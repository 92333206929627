import { Injectable, Injector } from '@angular/core';
import { RestService } from 'angular4-hal';
import { User } from '@app/data/models/user.model';
import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService<User> {
  constructor(injector: Injector) {
    super(User, 'users', injector);
  }
}
