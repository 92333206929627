import { Injectable, Injector } from '@angular/core';
import { RestService } from 'angular4-hal';
import { ContractorContract } from '@app/data/models/contract/contractor-contract.model';
import { BaseService } from '../base-service';

@Injectable({
  providedIn: 'root'
})
export class ContractorContractService extends BaseService<ContractorContract> {
  constructor(injector: Injector) {
    super(ContractorContract, 'contractor-contracts', injector);
  }
}
