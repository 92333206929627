import { Injectable, Injector } from '@angular/core';
import { RestService } from 'angular4-hal';
import { Agent } from '@app/data/models/agent.model';
import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root'
})
export class AgentService extends BaseService<Agent> {
  constructor(injector: Injector) {
    super(Agent, 'agents', injector);
  }
}
