import { Injectable, Injector } from '@angular/core';
import { RestService } from 'angular4-hal';
import { Bill } from '../models/transaction/bill.model';
import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root'
})
export class BillingService extends BaseService<Bill> {

  constructor(injector: Injector) {
    super(Bill, 'bills', injector);
  }
}
