import { Injectable, Injector } from '@angular/core';
import { RestService } from 'angular4-hal';
import { Phase } from '../../models/project/phase.model';
import { BaseService } from '../base-service';

@Injectable({
  providedIn: 'root'
})
export class PhaseService extends BaseService<Phase> {
  constructor(injector: Injector) {
    super(Phase, 'phases', injector);
  }
}
