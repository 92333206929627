import { Unit } from '../project/unit.model';
import { Amenity } from '../project/amenity.model';
import { PaymentSchedule } from './payment-schedule.model';
import { BaseModel } from '../base.model';

export class LineItem extends BaseModel {
    unit: Unit;
    description: string;
    totalContractPrice: number;
    paymentSchedules: PaymentSchedule[];
    amenity: Amenity;
}
