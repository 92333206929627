import { Injectable, Injector } from '@angular/core';
import { RestService } from 'angular4-hal';
import { AccountDetailType } from '@app/data/models/accounting/account-detail-type.model';
import { BaseService } from '../base-service';

@Injectable({
  providedIn: 'root'
})
export class AccountDetailTypeService extends BaseService<AccountDetailType> {
  constructor(injector: Injector) {
    super(AccountDetailType, 'account-detail-types', injector);
  }
}
