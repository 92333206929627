import { Injectable, Injector } from '@angular/core';
import { RestService } from 'angular4-hal';
import { BaseService } from './base-service';
import { Investor } from '../models/investor.model';

@Injectable({
  providedIn: 'root'
})
export class InvestorService extends BaseService<Investor> {

  constructor(injector: Injector) {
    super(Investor, 'investors', injector);
  }
}
