import { Injectable, Injector } from '@angular/core';
import { RestService } from 'angular4-hal';
import { Broker } from '@app/data/models/broker.model';
import { BaseService } from './base-service';

@Injectable({
  providedIn: 'root'
})
export class BrokerService extends BaseService<Broker> {
  constructor(injector: Injector) {
    super(Broker, 'brokers', injector);
  }
}
