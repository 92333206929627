import { Injectable, Injector } from '@angular/core';
import { RestService } from 'angular4-hal';
import { Block } from '../../models/project/block.model';
import { BaseService } from '../base-service';

@Injectable({
  providedIn: 'root'
})
export class BlockService extends BaseService<Block> {
  constructor(injector: Injector) {
    super(Block, 'blocks', injector);
  }
}
