import { Injectable, Injector } from '@angular/core';
import { RestService } from 'angular4-hal';
import { AccountType } from '@app/data/models/accounting/account-type.model';
import { BaseService } from '../base-service';

@Injectable({
  providedIn: 'root'
})
export class AccountTypeService extends BaseService<AccountType> {
  constructor(injector: Injector) {
    super(AccountType, 'account-types', injector);
  }
}
