import { Injectable, Injector } from '@angular/core';
import { RestService } from 'angular4-hal';
import { Amenity } from '../../models/project/amenity.model';
import { BaseService } from '../base-service';

@Injectable({
  providedIn: 'root'
})
export class AmenityService extends BaseService<Amenity> {
  constructor(injector: Injector) {
    super(Amenity, 'amenities', injector);
  }
}
